.navFont {
  font-family: 'Oswald', sans-serif;
}

.navLogo {
border-bottom: dashed 2px #879e93
}

.navLink:after {
  display: block;
  content: "";
  border-bottom: dashed 2px #879e93;
  transform: scaleX(0);
  transition: 250ms ease-in-out;
}

.navLink:hover:after {
  transform: scaleX(1);
}