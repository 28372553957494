.tabs {
    padding: .50rem 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
    Margin-left: -1px;
    background-color: rgba(0,0,0,.07);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.tab {
    background-color: #fff;
    height: auto;
    padding: 1rem;
}

.border1 {
    border: 1.5px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.span {
    font-weight: bold;
}
