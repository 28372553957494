/* ContactUs.css */

.contact-container {
  margin-top: 5rem;
}

.contact-header {
  margin-bottom: 1rem;
}

.contact-paragraph {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.general-info-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.map-placeholder {
  background-color: #e9e9e9;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
