.searchMargin {
  margin-top: 0vh;
  margin-bottom: 5vh;
}

.bottom {
    position: fixed !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }

@media only screen and (max-width: 900px) {
  .bottom {
    position: relative !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}
