/* Override active, focus, and visited styles for links */
.activeLink a,
.activeLink a:active, 
.activeLink a:focus,
.activeLink a:visited {
    color: inherit !important;
    text-decoration: none !important;
}

/* Override the background color of the .list-group-item itself for active, hover, and focus states */
.activeLink.list-group-item,
.activeLink.list-group-item:active, 
.activeLink.list-group-item:hover, 
.activeLink.list-group-item:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
}
